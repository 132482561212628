// form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["textarea"]
    static values = {
        cancelUrl: String,
        autoFocus: { type: Boolean, default: false },
        escapeRemovesFrame: { type: Boolean, default: false }
    }

    connect() {
        if (!this.hasTextareaTarget) return

        this.textareaTarget.rows = 1
        this.resizeTextarea()
        if (this.autoFocusValue) {
            this.focusWithCursorAtEnd()
        }
    }

    handleKeydown(event) {
        if (event.key === 'Enter') {
            event.preventDefault()

            if (event.altKey || event.shiftKey) {
                this.textareaTarget.setRangeText('\n',
                    this.textareaTarget.selectionStart,
                    this.textareaTarget.selectionEnd,
                    'end'
                )
                this.resizeTextarea()
            } else {
                const value = this.textareaTarget.value.trim()
                if (value !== '') {
                    this.handlingKeyboard = true
                    this.save()
                }
            }
        } else if (event.key === 'Escape') {
            event.preventDefault()
            this.handlingKeyboard = true
            this.cancel()
        }
    }

    handleBlur(event) {
        if (event.relatedTarget?.hasAttribute('data-turbo-confirm')) {
            return;
        }
        if (!this.handlingKeyboard) {
            this.cancel()
        }
    }
    handleInput() {
        this.resizeTextarea()
    }

    focusWithCursorAtEnd() {
        this.textareaTarget.focus()
        const cursorPosition = this.textareaTarget.value.length
        this.textareaTarget.setSelectionRange(cursorPosition, cursorPosition)
    }

    resizeTextarea() {
        const textarea = this.textareaTarget
        textarea.style.height = 'auto'
        textarea.style.height = textarea.scrollHeight + 2 + 'px'
    }

    save() {
        this.element.closest('form').requestSubmit()
    }

    async cancel() {
        const frame = this.element.closest('turbo-frame')
        if (this.escapeRemovesFrameValue && this.cancelUrlValue === "") {
            frame.remove()
        } else {
            Turbo.visit(this.cancelUrlValue, { frame: frame.id })
        }
    }
}