import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
    static targets = ["preference"]
    static values = {
        popupId: String
    }

    async togglePreference(event) {
        const hide = event.target.checked

        const request = new FetchRequest('put', '/users', {
            body: JSON.stringify({
                user: {
                    [`hide_popup_${this.popupIdValue}`]: hide
                }
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })

        try {
            const response = await request.perform()
            if (!response.ok) {
                event.target.checked = !hide
                console.error("Failed to update preference")
            }
        } catch (error) {
            event.target.checked = !hide
            console.error("Error updating preference:", error)
        }
    }
}