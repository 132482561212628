// input_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]
    static values = {
        cancelUrl: String,
        autoFocus: { type: Boolean, default: false }
    }

    connect() {
        if (!this.hasInputTarget) return

        if (this.autoFocusValue) {
            this.focusWithCursorAtEnd()
        }
    }

    handleKeydown(event) {
        if (event.key === 'Escape') {
            event.preventDefault()
            this.handlingKeyboard = true
            this.cancel()
        }
    }

    handleBlur() {
        if (!this.handlingKeyboard) {
            this.cancel()
        }
        this.handlingKeyboard = false
    }

    focusWithCursorAtEnd() {
        this.inputTarget.focus()
        const cursorPosition = this.inputTarget.value.length
        this.inputTarget.setSelectionRange(cursorPosition, cursorPosition)
    }

    async cancel() {
        const frame = this.element.closest('turbo-frame')
        await Turbo.visit(this.cancelUrlValue, { frame: frame.id })
    }
}